import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import images from '../../assets/images';
import { YMaps, Map, Placemark,ZoomControl } from 'react-yandex-maps';
import { setCategory } from '../../redux/actions/cart';
import './style.css';

function Footer(props) {
  const { whatsap, telega } = images;
  const { mobile, id } = props;
  const dispatch = useDispatch();
    return (
      <div id={id} className="footer_container">
     
        <div className="footer_down">
          <div className="coperait_container">
            <div className={`${mobile ? 'mobile-coperait_txt' : 'coperait_txt'}`}>МЫ ВСЕГДА ОТКРЫТЫ К ОБЩЕНИЮ</div> 
          </div>
          <div className="telephone_footer_container">
            <div className={`${mobile ? 'mobile-telephone_footer' : 'telephone_footer'}`}>+7 906 431 63 63</div> 
          </div>
          <div className="telephone_footer_container">
            <div className={`${mobile ? 'mobile-telephone_footer' : 'telephone_footer'}`}>Майкоп, Постовая 10</div> 
          </div>
          <div className="visa_container">
            <Link to={'https://wa.me/79064316363'}><div style={{
              width: '50px',
              height: '50px',
              justifyContent: 'center',
              backgroundColor: 'white',
              borderRadius: '50px',
              display: 'flex',
              alignItems: 'center',
             }}><img src={whatsap} alt="visa" width="35" height="35" /></div></Link>
            <Link to={'https://t.me/TTL_SOLUTIONS'}><img src={telega} alt="mastercard" width="50" height="50" /></Link>
          </div>
        </div>
      </div>
    );
}

export default Footer;
