import React from 'react';
import Title from '../Title';
import './style.css'
import { about_plus } from '../../config';
import images from '../../assets/images';
 
export default function PlusBlock(props) {
  const { mobile, id } = props;
  const { check } = images;

  return (
    <div id={id} className="plusItemWrapper">
      {/* <div className='layout'></div> */}
      {
        mobile 
        ? 
          <>
            <Title size={28} margin="50px 0px 0px 0px" color="black" text="Что вы получите?" /> 
            <div style={{zIndex: 9}} className="mobilePlusRightBlock">
            
                  {
              about_plus.map((item,i) => (<div key={i+'1p'} className="mobilePlusItem">
                <div className="mobilePlusImgContainer">
                  <img className='mobilePhotoCheck' src={check} alt="check" />
                </div>
                <div className="mobilePlusInfoContainer">
                  <div className="mobilePlusTitle">{ item.title }</div>
                  <div className="mobilePlusDescription">{ item.description }</div>
                </div>
              </div>))
            }
            </div>
          </>
        :
        <>
          <Title margin="155px 0px 0px 0px" color="black" text="Что вы получите?" />
          {/* <div style={{borderColor:'white',zIndex: 9}} className="underlineTextMonument"></div> */}
          <div style={{zIndex: 9}} className="plusRightBlock">
            {
              about_plus.map((item,i) => (<div key={i+'1p'} className="plusItem">
                <div className="plusImgContainer">
                  <img className='photoCheck' src={check} alt="check" />
                </div>
                <div className="plusInfoContainer">
                  <div className="plusTitle">{ item.title }</div>
                  <div className="plusDescription">{ item.description }</div>
                </div>
              </div>))
            }
          </div>
        </>
      }
    </div>
  );
}
 