import React from 'react';
import Title from '../Title';
import './style.css'
import { about_why } from '../../config'; 
import images from '../../assets/images'; 
 
export default function WhyBlock(props) {
  const { mobile, id } = props;
  const { check, magic, win, people  } = images;

const arrimg = [win, people, magic]
  return (
    <div className="mobileWhyItemWrapper">
      {/* <div className='layout'></div> */}
      {
        mobile 
        ? 
          <>
            <Title size={'32px'} margin="70px 0px 0px 0px" color="black" text="Почему стоит выбрать нас?" customclass="centerWhy"  /> 
            <div id={id} className='mobileWhyDescription'>
              Наши специалисты с радостью поделятся своей экспертизой, основанной на знании того, что сейчас работает на рынке.
            </div> 
            <div className="mobileWhyRightBlock">
              {
                about_why.map((item,i) => (<div key={i+'1p'} className="mobileWhyItem">
                  <div className="mobileWhyImgContainer">
                    <img className='mobileWhyPhotoCheck' src={arrimg[i]} alt="check" />
                  </div>
                  <div className="mobileWhyInfoContainer">
                    <div className="mobileWhyTitle">{ item.title }</div>
                    <div className="mobileWhyDescriptionCart">{ item.description }</div>
                  </div>
                </div>))
              }
            </div>
          </>
        :
        <>
          <Title margin="155px 0px 0px 0px" color="black" text="Почему стоит выбрать нас?" /> 
          <div id={id} className='whyDescription'>
          Наши специалисты с радостью поделятся своей экспертизой, основанной на знании того, что сейчас работает на рынке.
          </div> 
          <div style={{zIndex: 9}} className="whyRightBlock">
            {
              about_why.map((item,i) => (<div key={i+'1p'} className="whyItem">
                <div className="whyImgContainer">
                  <img className='whyPhotoCheck' src={arrimg[i]} alt="check" />
                </div>
                <div className="whyInfoContainer">
                  <div className="whyTitle">{ item.title }</div>
                  <div className="whyDescriptionCart">{ item.description }</div>
                </div>
              </div>))
            }
          </div>
        </>
      }
    </div>
  );
}
 