import React, { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; 
import { slide as Menu } from 'react-burger-menu';
import { Link, useNavigate,useLocation } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';
import { header } from '../../redux/selectors';
import { change_page } from '../../redux/actions/app';
import { setPopupMainMsg } from '../../redux/actions/cart';
import images from '../../assets/images';
import './style.css';
import OrangeButton from '../OrangeButton';

function HeaderMenu(props) {
  const { statuy, cart, phone, map, time, logo, whatsaphead,telegahead }= images;
  const { onClick, userName, page, mobile, isAuth} = props;
 
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {   
    // dispatch(change_page(localStorage.getItem('page'))); 
    dispatch(setPopupMainMsg(''));
  },[page]); 

  const {hash, key} = useLocation()
  useEffect(()=>{
      if(hash){
        const targetElement = document.getElementById(hash.substring(1))
        targetElement?.scrollIntoView({behavior: 'smooth'})
      }
  }, [key, hash])

  const headers_tab = [ 
    {
      title: 'О компании',
      key:"about",
      rout: '/#about',
    },
    {
      title: 'Наши сервисы',
      key:"services",
      rout: '/#services',
    },
  ];

  const headers_mobile_tab = [ 
    {
      title: 'О компании',
      key:"about",
      rout: '/#about',
    },
    {
      title: 'Наши сервисы',
      key:"services",
      rout: '/#services',
    },
    {
      title: 'Контакты',
      key:"contact",
      rout: '/#contact',
    },
  ];
 
  let st = {}
 
    return (
      <div className={` ${ mobile ? 'mobileHeaderWrapper' : 'headerWrapper' }`}>
        { mobile ? 
          <>
            <div className='mobileTopHeader'>
            <Menu >
              { headers_mobile_tab.map(({ key, rout, title }) => {
                st = key === page ? 'active' : ''
                return <Link key={key} to={rout}><div className={`tabMobile ${st}`} onClick={(e) => { onClick(e); }}>{title}</div></Link>
              })} 
              {/* (<Link key={i} className={`tabMobile ${tab === i ? 'active' : ''}`} to={item.to}><div id={i} onClick={(e) => { dispatch(setTabHeader(Number(e.target.id))) }} >{item.title}</div></Link>))
              }   */}
            </Menu>
            <div className='mobileHeaderButtonContainer'>
              <Link to={'https://wa.me/79064316363'}><img src={whatsaphead} alt="mastercard" width="35" height="35" /></Link>
              <Link to={'https://t.me/TTL_SOLUTIONS'}><img src={telegahead} alt="mastercard" width="35" height="35" /></Link> 
            </div>
            </div>  
          </>
          :
          <>
            <div className='topHeader'>
            <div style={{margin: '15px',maxHeight: '80px', display: mobile ? 'none': 'block' }} className='lableContainer'> 
              <img style={{margin: '15px',maxHeight: '80px' }} src={logo} alt="profile" width="84" height="80"  />
           
            </div>
            <div className="catigories">
            { headers_tab.map(({ key, rout, title }) => {
                st = key === page ? 'active' : ''
                return <Link key={key} to={rout}><div className={`tab ${st}`} onClick={(e) => { onClick(e); }}>{title}</div></Link>
                return <Link key={key} to={rout}><div id={key} className={`tab ${st}`} onClick={(e) => { onClick(e); }}>{title}</div></Link>
              })} 
            </div>
            <div className='headerButtonContainer'>
              <Link to={'https://wa.me/79064316363'}><img src={whatsaphead} alt="mastercard" width="35" height="35" /></Link>
              <Link to={'https://t.me/TTL_SOLUTIONS'}><img src={telegahead} alt="mastercard" width="35" height="35" /></Link>
              <OrangeButton text="Контакты" shadow="none" width={180} height={40} color="#ff4c7c" onClick={()=>{navigate('/#contact')}} />
            </div>
            </div> 
          </> 
        }
      </div>
    );
}

export default HeaderMenu;