import logo from './images/logo.jpeg';
import one from './images/1.jpeg';
import two from './images/2.jpeg';
import three from './images/3.jpeg';
import main from './images/main.jpeg'; 
import win from './images/win.svg';
import magic from './images/magic.svg';
import check from './images/check.svg';
import telega from './images/telega.svg';
import telegahead from './images/telegaheat.svg';
import whatsap from './images/whatsap.svg';
import whatsaphead from './images/whatsaphead.svg';
import arrow from './images/arrow.svg';
import people from './images/people.svg';
import arrowblack from './images/arrowblack.svg';
 
export default {
  whatsaphead,
  whatsap,
  logo,
  one,
  two,
  three,
  main,
  win,
  magic,
  check,
  telega, 
  telegahead, 
  people,
  arrow,
  arrowblack
}