import React from 'react';
import Title from '../Title';
import OrangeButton from '../OrangeButton';
import Timer from '../Timer';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; 
import { setCategory } from '../../redux/actions/cart';

import styled, { keyframes } from 'styled-components';
import { bounce, bounceInUp } from 'react-animations'; 
import images from '../../assets/images'
import './style.css';

export default function MainBlock(props) {

  const { mobile } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Bounce = styled.div`animation: 3s ${keyframes`${bounce}`}  infinite  `;
  const BounceIn = styled.div`animation: 0.75s ${keyframes`${bounceInUp}`} linear `; 
  const { arrow }= images;
  return (
    <div className={`${mobile ? 'mobileItemWrapper' : 'itemWrapper'}`}> 
      <div className="colInfoBlock"> 
        <div className={`${mobile ? 'mobile-wrapper_maintxt' : 'wrapper_maintxt'}`} > 
          <div style={{ width: mobile ? '93%' : '80%' }} className="colTimerBlock">
            <div style={{ flexDirection: 'column'}} className={`${mobile ? 'mobile-txtTimer' : 'txtTimer'}`}><BounceIn><span className={`${mobile ? 'mobile-spantxt' : 'spantxt'}`}>TTL* Продвижение Бизнеса</span></BounceIn> <span  style={{ fontSize: mobile ? '14px' : '24px',  
              lineHeight: mobile ? '20px' : 1.50,
              fontFamily: 'IBM Plex Serif',
              fontWeight: 300,
              padding:`${mobile ? '10px 0px' : '0px 0px 30px 0px'}`,
              textAlign: 'center',
              color: '#fff',
              maxWidth: '700px',
              margin: `${mobile ? '30px auto' : '0 auto'}` }}>TTL (trough the line)* - сочетает в себе ATL и BTL инструменты продвижения</span>
            </div>
          </div>
        </div>
        <Bounce onClick={()=>{navigate('/#about')}} style={{position: 'absolute', bottom: '5%',left: `${mobile ? '40%' : '47%'}`, cursor: 'pointer' }}><img style={{margin: '0px',maxHeight: '80px' }} src={arrow} alt="profile" width="65" height="38" /></Bounce>
      </div>
    </div>
  );
};