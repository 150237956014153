import React from 'react'; 
import Title from '../Title';
import './style.css'; 
import { about_product,answersContinum,answersNew } from '../../config';  
import Answer from '../Answer';

export default function AboutProduct(props) { 
  const { mobile, id } = props;
  let st = {fontSize: '10px'}
  return (
    <div id={id} className={`${mobile ? 'mobileAboutWrapper' : "aboutWrapper"}`}>
      {
        mobile ?
        <>
         <Title size={'34px'} margin="40px 0px 50px 0px" text="Наши сервисы" />
          <div className='mobileAboutDescription'>
            {
              about_product.descriptor
            }
          </div>
          <div className='mobileServicesContainer'>
            <div className='mobileServicesContainerLeft'>
              <div className='mobileServicesLeftHead'>Создание нового бизнеса</div>
              {
                answersNew.map((item,ids) => ( 
                <Answer mobile={mobile} style={st} title={item.request} description={item.response} key={ids} />
                ))
              }
            </div>
            <div className='mobileServicesContainerRight'>
              <div className='mobileServicesRightHead'>Развитие  бизнеса</div>
              {
                answersContinum.map((item,ids) => ( 
                <Answer mobile={mobile} style={st} title={item.request} description={item.response} key={ids} />
                ))
              }
            </div>  
          </div> 
          <div className='mobileAboutTopWrapper'>
            <div className='aboutPhotoWrapper'>
            <div className='photoAboutOne'></div> 
            </div>
            <div className='aboutTextWrapper'>
              <div className='aboutTitleServices'>
                  {
                    about_product.text_description_1
                  }
                </div>
                <div className='aboutDescriptionServices'>
                  {
                    about_product.text
                  }
              </div> 
            </div>
          </div>
          <div className='mobileAboutBottomWrapper'>
            <div className='aboutTextWrapper'>
              <div className='aboutTitleServices'>
                {
                  about_product.text_description_2
                }
              </div>
              <div className='aboutDescriptionServices'>
                {
                  about_product.text_2
                }
              </div> 
            </div>
            <div className='aboutPhotoWrapper'>
            <div className='photoAboutTwo'></div> 
            </div>
          </div>
          <div className='mobileAboutTopWrapper'>
            <div className='aboutPhotoWrapper'>
              <div className='photoAboutThree'></div> 
            </div>
            <div className='aboutTextWrapper'>
              <div className='aboutTitleServices'>
                {
                  about_product.text_description_3
                }
              </div>
              <div className='aboutDescriptionServices'>
                {
                  about_product.text_3
                }
              </div> 
            </div>
          </div>
        </> :
        <>
          <Title margin="10px 0px 50px 0px" text="Наши сервисы" />
          <div className='aboutDescription'>
            {
              about_product.descriptor
            }
          </div>
            <div className='servicesContainer'>
              <div className='servicesContainerLeft'>
                <div className='servicesLeftHead'>Создание нового бизнеса</div>
                {
                  answersNew.map((item,ids) => ( 
                  <Answer mobile={mobile} style={st} title={item.request} description={item.response} key={ids} />
                  ))
                }
              </div>
              <div className='servicesContainerRight'>
                <div className='servicesRightHead'>Развитие  бизнеса</div>
                {
                  answersContinum.map((item,ids) => ( 
                  <Answer mobile={mobile} style={st} title={item.request} description={item.response} key={ids} />
                  ))
                }
              </div>  
            </div> 
          <div className='aboutTopWrapper'>
            <div className='aboutPhotoWrapper'>
            <div className='photoAboutOne'></div> 
            </div>
            <div className='aboutTextWrapper'>
              <div className='aboutTitleServices'>
                  {
                    about_product.text_description_1
                  }
                </div>
                <div className='aboutDescriptionServices'>
                  {
                    about_product.text
                  }
              </div> 
            </div>
          </div>
          <div className='aboutBottomWrapper'>
            <div className='aboutTextWrapper'>
              <div className='aboutTitleServices'>
                {
                  about_product.text_description_2
                }
              </div>
              <div className='aboutDescriptionServices'>
                {
                  about_product.text_2
                }
              </div> 
            </div>
            <div className='aboutPhotoWrapper'>
            <div className='photoAboutTwo'></div> 
            </div>
          </div>
          <div className='aboutTopWrapper'>
            <div className='aboutPhotoWrapper'>
              <div className='photoAboutThree'></div> 
            </div>
            <div className='aboutTextWrapper'>
              <div className='aboutTitleServices'>
                {
                  about_product.text_description_3
                }
              </div>
              <div className='aboutDescriptionServices'>
                {
                  about_product.text_3
                }
              </div> 
            </div>
          </div>
        </>
      }
    </div>
  );
}
 